import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "simple-page",
    loadChildren: () => import("../../components/simple-page/simple-page.module").then((m) => m.SimplePageModule),
  },
  {
    path: "stat",
    loadChildren: () => import("../../components/stat/stat.module").then((m) => m.StatModule),
  },
  {
    path: "config",
    loadChildren: () => import("../../components/config/config.module").then((m) => m.ConfigModule),
  },
  {
    path: 'users',
    loadChildren: () => import("../../components/users/users.module").then(m => m.UsersModule)
  },{
    path: 'user/:id',
    loadChildren: () => import("../../components/user/user.module").then(m => m.UserModule)
  },{
    path: 'services',
    loadChildren: () => import("../../components/services/services.module").then(m => m.ServicesModule)
  },{
    path: 'addservice',
    loadChildren: () => import("../../components/addservice/addservice.module").then(m => m.AddserviceModule)
  },{
    path: 'editservice/:id',
    loadChildren: () => import("../../components/editservice/editservice.module").then(m => m.EditserviceModule)
  },{
    path: 'orders',
    loadChildren: () => import("../../components/orders/orders.module").then(m => m.OrdersModule)
  },{
    path: 'pushes',
    loadChildren: () => import("../../components/pushes/pushes.module").then(m => m.PushesModule)
  }
];
