import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RequestService {

    public url = 'https://app.lend4you.org/server/api/';

    constructor(public http: HttpClient, public translate:TranslateService) { }

    get(path: string) {
        let promise = new Promise<any>((resolve, reject) => {
            let url = this.url + path;

            this.http.get(url)
                .toPromise()
                .then(res => {
                    resolve(res);
                }).catch(async err => {
                    //await(await this.alertCtrl.create({header:this.translate.instant('ERROR'), message:this.translate.instant(err.error)})).present();
                    reject(err);
                });
        })
        return promise;
    }

    post(path, data) {
        let promise = new Promise<any>((resolve, reject) => {
            let url = this.url + path;

            this.http.post(url, data)
                .toPromise()
                .then(res => {
                    resolve(res);
                }).catch(async err => {
                    //await(await this.alertCtrl.create({header:this.translate.instant('ERROR'), message:this.translate.instant(err.error)})).present();
                    reject(err);
                });
        })
        return promise;
    }

    patch(path, data) {
        let promise = new Promise<any>((resolve, reject) => {
            let url = this.url + path;

            this.http.patch(url, data)
                .toPromise()
                .then(res => {
                    resolve(res);
                }).catch(async err => {
                    //await(await this.alertCtrl.create({header:this.translate.instant('ERROR'), message:this.translate.instant(err.error)})).present();
                    reject(err);
                });
        })
        return promise;
    }

    delete(path) {
        let promise = new Promise<any>((resolve, reject) => {
            let url = this.url + path;

            this.http.delete(url)
                .toPromise()
                .then(res => {
                    resolve(res);
                }).catch(async err => {
                    //await(await this.alertCtrl.create({header:this.translate.instant('ERROR'), message:this.translate.instant(err.error)})).present();
                    reject(err);
                });
        })
        return promise;
    }
}